<template>
  <div class="page">
    <transition name="slide-fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Billing',
};
</script>

<style lang="stylus" scoped></style>
