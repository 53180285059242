<template>
  <div class="billing-main">
    <div class="billing-main__head">
      <div class="billing-main__title">
        <page-title>
          {{ $t('title') }}
        </page-title>
        <base-button v-if="isShowBalance" class="billing-main__add" @click="addPayment">
          {{ $t('add') }}
        </base-button>
      </div>
      <!--      <tabs :list="accessNav" class="medium-title">-->
      <tabs :list="filtredNav" class="medium-title">
        <template v-slot:item="{ item }">
          <router-link active-class="active" :to="item.to">
            {{ item.title }}
          </router-link>
        </template>
      </tabs>
    </div>
    <div class="billing-main__body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
import addPayment from '@/mixins/billing/addPayment';
import { postpaid, filterObject, findAllByKeyIncluded } from '@/utils/postpaid';
import updateBalance from '@/mixins/billing/updateBalance';
import qs from 'qs';

export default {
  name: 'Billing',
  components: {
    tabs,
  },
  mixins: [addPayment, updateBalance],
  data() {
    return {
      nav: [
        {
          title: this.$t('nav.deposit'),
          to: { name: 'BillingDeposit' },
          access: ['finance.payment' && this.isShowBalance],
        },
        {
          title: this.$t('nav.history'),
          access: ['finance.expense'],
          to: { name: 'BillingHistory' },
        },
        {
          title: this.$t('nav.docs'),
          access: ['finance.customdocs'],
          to: { name: 'BillingDocs' },
        },
        // {
        //   title: this.$t('nav.orders'),
        //   to: { name: 'BillingOrders' },
        // },
        {
          title: this.$t('nav.auto'),
          access: ['finance.payment.recurring.settings'],
          to: { name: 'BillingAutoPayment' },
        },

        {
          title: this.$t('nav.promised'),
          access: ['finance.promisepayment.add'],
          to: { name: 'BillingPromisePayment' },
        },
        {
          title: this.$t('nav.payers'),
          access: ['customer.profile'],
          to: { name: 'BillingPayers' },
        },
      ],
      postpaid: postpaid,
    };
  },
  computed: {
    access() {
      return this.$store.state.moduleProfile.access;
    },
    accessNav() {
      return this.nav.filter(i => {
        return !i.access || this.access[i.access[0]];
      });
    },

    filtredNav() {
      if (this.isShowBalance) return this.nav;
      else return this.nav.filter(x => x.to.name !== 'BillingDeposit');
    },
  },
  // watch: {
  //   '$route.path': {
  //     handler: function (event) {
  //     },
  //     immediate: true,
  //   },
  // },
  // beforeRouteEnter(_to, _from, next) {
  //   next(vm => {
  //     const isHideDeposit = vm.$store.state.moduleStart.userWithoutPayment;
  //     if (isHideDeposit) {
  //       console.log('222222222');
  //       vm.$router.push({ name: 'BillingDocs' }).catch(() => {});
  //     }
  //   });
  // },
  mounted() {
    this.$store.dispatch('moduleStart/fetchDasboardInfo', { func: 'dashboard.info' });
    this.$store.dispatch('moduleStart/fetchDasboardInfo', { func: 'dashboard.services' });
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Биллинг",
    "nav": {
      "deposit": "Пополнение счета",
      "history": "История платежей",
      "docs": "Документы",
      "auto": "Автоплатеж",
      "orders": "Мои услуги",
      "promised": "Обещанный платеж",
      "payers": "Плательщики"
    },
    "add": "Пополнить баланс"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.billing-main {
  &__title {
    margin-bottom: 1.5rem;
    +breakpoint(sm-and-up) {
      flexy(space-between, center);
    }
  }
  &__add {
    margin-top: 1rem;

    +breakpoint(sm-and-up) {
      margin-top: 0;
    }
  }
  &__body {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
